#menu-button {
     height: 20px;
     width: 40px;

     position: relative;

     &::before,
     &::after {
          content: "";
          position: absolute;
          right: 0;
          height: 2px;
          background-color: white;
          transition: 300ms;
     }
     &::before {
          top: 2px;
          width: 100%;
     }
     &::after {
          bottom: 2px;
          width: 60%;
     }
     &:hover,
     &.active {
          &::after {
               width: 100%;
          }
     }

     &.active {
          &::after {
               rotate: 45deg;
          }
          &::before {
               rotate: -45deg;
          }
     }
}
