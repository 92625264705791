.app-layout {
     min-height: 100vh;
     display: flex;
     flex-direction: column;
}

footer {
     color: white;
     padding: 0 10%;
     margin-top: auto;
     a,
     p {
          scale: 0;
          opacity: 0;
          transition: 2s;
     }
     .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 40px 0;
          gap: 30px;
          a:first-child {
               max-width: 200px;
          } 
          .socials {
               display: flex;
               max-width: 50%;
               width: 190px;
               justify-content: space-between;
          }
     }
     .bottom {
          display: flex;
          justify-content: space-between;
          padding: 50px 0;
          font-size: 16px;
          row-gap: 50px;
          .links {
               display: flex;
               flex-direction: column;
               gap: 10px;
               width: 22%;
          }

          a {
               color: #dddddd;
               display: flex;
               gap: 10px;
               align-items: center;
          }
     }
     &.active {
          a,
          p {
               scale: 1;
               opacity: 1;
          }
     }
}

@media (max-width: 700px) {
     footer {
          .bottom {
               flex-wrap: wrap;
               .links {
                    width: 46%;
               }
          }
     }
}
