nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.02);
  mix-blend-mode: difference;
  backdrop-filter: blur(25px);
  z-index: 100;
  transition: 0.5s;
  .navbar {
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 20px 10%;
    align-items: center;
    gap: 20px;
    a {
      height: 70px;
    }
  }
  .languages {
    color: white;
    display: flex;
    gap: 10px;
    justify-self: flex-end;
    p {
      cursor: pointer;
      position: relative;
    }
    p::before {
      content: "";
      transition: 300ms;
      translate: -50% -100%;
    }
    p.active {
      color: #c0ff03;
    }
    p.active::before {
      position: absolute;
      bottom: 109%;
      left: 50%;
      translate: -50%;
      height: 300px;
      width: 2px;
      background-color: #c0ff03;
    }
  }
  .menu-button {
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
