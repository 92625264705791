.scroll-section .title {
  color: white;
  padding: 120px 10% 100px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  h1,
  h2 {
    font-family: "Title_font";
    translate: -100%;
    transition: 1.6s;
    opacity: 0;
  }
  h2 {
    font-size: 52px;
    transition: 1.8s;
  }
  p {
    translate: 100%;
    transition: 2s;
    opacity: 0;
    max-width: 700px;
    align-self: flex-end;
    margin-top: 100px;
    width: 50%;
    font-size: 24px;
    line-height: 33px;
    max-width: 500px;
  }
  &.active {
    h1,
    h2,
    p {
      opacity: 1;
      translate: 0;
    }
  }
}
.scroll-section .body {
  display: flex;
}
@media (max-width: 1050px) {
  .scroll-section .title {
    padding: 2vw 10%;
    p {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
    }
    h1 {
      font-size: 60px;
    }
    h2 {
      font-size: 40px;
    }
  }
}
@media (max-width: 700px) {
  .scroll-section .title {
    p {
      font-size: 15px;
      line-height: normal;
      margin-top: 40px;
    }
    h1 {
      font-size: calc(30px + 3vw);
    }
    h2 {
      font-size: calc(20px + 2vw);
    }
  }
}
