.circle-animation-comp {
    .image {
    position: absolute;
  }
  .plastic-image {
    width: 100%;
    height: 100%;
    z-index: 4;
    // opacity: 0.6;
    mix-blend-mode: overlay;
  }
  .showcase-noise {
    width: 100%;
    height: 100%;
    z-index: 3;
    mix-blend-mode: overlay;
    // display: none;
  }

  .big-circle {
    width: 100%;
    left: 50%;
    translate: -50%;
    top: 50%;
    z-index: 2;
    // filter: contrast(200%);
    animation: bigcircle 100s cubic-bezier(1, 1, 0, 0) infinite;
  }
  .small-circle {
    width: 70%;
    left: 50%;
    translate: -50%;
    top: 10%;
    z-index: 1;
    // filter: contrast(200%);
    animation: smallcircle 100s cubic-bezier(1, 1, 0, 0) infinite;
  }
}
