.profile-comp {
  position: relative;
  min-width: 80px;
  color: white;
  aspect-ratio: 1/1;
  max-width: 100px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .icon {
    background: rgba(253, 253, 253, 0.1);
    backdrop-filter: blur(15px);
    position: absolute;
    bottom: -5%;
    right: -5%;
    width: 40%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .social {
    font-size: 150%;
  }
}
