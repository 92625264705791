.showcase {
  height: calc(100vh + 1px);
  color: white;
  // background-color: rgb(0, 0, 0);
  overflow: hidden;
  position: relative;
  // z-index: 10;

  .showcase-content {
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 10;
    color: #fdfdfd;
  }

  .showcase-text {
    // min-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateZ(10px);
    width: 80%;
    transition: 1s;
    max-width: 1300px;
    // opacity: 0;
  }
  .showcase-text h1 {
    font-family: "Title_font";
    font-size: 5vw;
  }
  a {
    translate: 0 300px;
    transition: 2s;
    opacity: 0;
    margin-top: 88px;
  }

  .showcase-text h1:first-child {
    translate: 0 300px;
    transition: 1s;
    opacity: 0;
  }
  .showcase-text h1:nth-child(3) {
    translate: 0 300px;
    transition: 2s;
    opacity: 0;
  }

  .middle-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    translate: 0 300px;
    opacity: 0;
    transition: 1.5s;
    p {
      max-width: 516px;
      width: 50%;
      font-size: 1.3vw;
    }
  }
}
.showcase.active {
  .showcase-text {
    h1:first-child,
    h1:nth-child(3),
    a,
    .middle-text {
      opacity: 1;
      translate: 0;
    }
  }
}

@keyframes bigcircle {
  0% {
    rotate: 0;
  }
  20% {
    translate: 40%;
  }
  40% {
    translate: 30% -70%;
  }
  60% {
    translate: -130% -100%;
  }
  80% {
    translate: -130% 0%;
  }
  100% {
    rotate: 720deg;
  }
}

@keyframes smallcircle {
  0% {
    rotate: 0;
  }
  20% {
    translate: -130% 0%;
  }
  40% {
    translate: -130% -100%;
  }
  60% {
    translate: 30% -70%;
  }
  80% {
    translate: 40%;
  }
  100% {
    rotate: 720deg;
  }
}

@media (max-width: 900px) {
  .showcase .showcase-text {
    text-align: center;
    p {
      display: none;
    }
    h1 {
      width: 100%;
      font-size: 7vw;
    }
  }
  .showcase {
    a {
      margin-top: 40px;
    }
  }
}
