.hamburger {
     border-bottom: 2px solid white;
     border-top: 2px solid white;
     position: relative;
     &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          height: 2px;
          background-color: white;
          width: 140%;
     }
}
