.about-section {
  background-color: white;
  padding: 150px 10%;
  * {
    transition: 2s;
  }

  a {
    translate: 0 300px;
    transition: 2s;
    opacity: 0;
  }

  .title .course {
    width: 300px;
    display: flex;
    gap: 0;
    justify-content: space-between;
    p {
      padding: 15px 5px;
      background-color: rgb(232, 232, 232);
      font-family: "Title_Font";
      border-radius: 40px;
      border: 5px solid rgb(255, 255, 255);
      font-size: 14px;
      min-width: 150px;
      width: 150px;
      text-align: center;
      transition: 500ms;
      // margin-right: -100px;
    }
    p:nth-child(1) {
      translate: 0px;
      &:hover {
        translate: -0px -30px;
        scale: 0.95;
        background-color: #c0ff03;
        color: rgb(49, 49, 49);
      }
    }
    p:nth-child(2) {
      translate: -100px;
      &:hover {
        translate: -100px -30px;
        scale: 0.95;
        background-color: #c0ff03;
        color: rgb(49, 49, 49);
      }
    }
    p:nth-child(3) {
      translate: -200px;
      &:hover {
        translate: -200px -30px;
        scale: 0.95;
        background-color: #c0ff03;
        color: rgb(49, 49, 49);
      }
    }
    p:nth-child(4) {
      translate: -300px;
      &:hover {
        translate: -300px -30px;
        scale: 0.95;
        background-color: #c0ff03;
        color: rgb(49, 49, 49);
      }
    }
  }
  &.dark {
    background-color: transparent;
    color: white;
    & .title {
      p {
        border-color: rgb(41, 41, 41);
        color: black;
      }
    }
  }
}

.about-section .title {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  opacity: 0;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  p {
    max-width: 140px;
    translate: 300px;
  }
  h1,
  h2 {
    font-family: "Title_font";
  }
  h1 {
    @media (min-width: 1000px) {
      font-size: 5vw;
    }
    @media (max-width: 1000px) {
      font-size: 10vw;
    }
  }

  h2 {
    font-size: 70px;
    translate: 200px;
  }
  img {
    width: 50%;
    max-width: 250px;
    translate: 100px;
  }
}

.about-section.active {
  a {
    translate: 0;
    opacity: 1;
  }
  .body {
    opacity: 1;
    div,
    p {
      translate: 0;
    }
  }
  .title {
    opacity: 1;
    h2,
    h3,
    p,
    img {
      translate: 0;
    }
  }
}

@media (max-width: 1300px) {
  .about-section {
    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .title div {
      max-width: 600px;
    }
  }
}

@media (max-width: 1000px) {
  .about-section {
    .title {
      h2 {
        font-size: 50px;
      }
      p {
        font-size: 15px;
      }
    }
    .body {
      p {
        font-size: 20px;
      }
      div {
        font-size: 15px;
        line-height: 20px;
      }
      padding-bottom: 20px;
    }
  }
}

@media (max-width: 600px) {
  .about-section {
    .title {
      .course {
        // background-color: green;
        width: auto;
        flex-wrap: nowrap;
      }
      div {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }
    .body {
      flex-wrap: wrap;
      div,
      p {
        width: 100%;
      }
    }
  }
}

@media (max-width: 430px) {
  .about-section {
    .title {
      h2 {
        font-size: 40px;
      }
    }
  }
}
