.single-new-section {
    color: white;
    padding: 220px 10% 50vh;
    h1 {
        padding-bottom: 50px;
        translate: -100%;
        opacity: 0;
        transition: 2s;
    }
    &>div{
        translate: -200px;
        transition: 2s;        opacity: 0;

    }
    &.active{
        h1,&>div{        translate: 0;
            opacity: 1;

         }
    }
}

@media (max-width: 1050px) {
    .single-new-section {
        .title-component {
            font-size: calc(20px + 5vw);
        }
    }
}
