.contact-section {
  color: white;
  padding: 170px 10%;
  text-align: center;
  p {
    font-size: 20px;
    line-height: 26px;
    max-width: 645px;
    margin: 0 auto;
    padding-bottom: 53px;
  }
  .items {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 30px;
    a {
      font-family: "Title_font";
      width: 32%;
      display: flex;
      gap: 5%;
      align-items: center;
      justify-content: center;
      font-size: 1.5vw;
    }
  }

  &.active {
  }
}

@media (max-width: 1000px) {
  .contact-section {
    p {
      font-size: calc(8px + 1vw);
      line-height: calc(12px + 1vw);
    }
    .items a {
      width: 48%;
      font-size: 2vw;
    }
  }
}

@media (max-width: 700px) {
  .contact-section {
    p {
      font-size: calc(8px + 1.5vw);
      line-height: calc(12px + 1.5vw);
    }
    .items a {
      width: 100%;
      font-size: 4vw;
    }
  }
}
