.about-image {
  transition: 1s;
  width: calc(90%);
  margin: -40vh 5%;
  // scale: 1;
  img {
     height: 80vh;
    width: 100%;
     object-fit: cover;
    object-position: 30%;
  }
  &.pastda {
    img {
      animation: tobottom 2s cubic-bezier(0.8, 0.76, 0.13, 1.06) forwards;
    }
  }
  &.pastda.active {
    img {
      animation: frombottom 2s cubic-bezier(0, 0.82, 0.58, 0.72) forwards;
    }
  }
  &.tepada {
    img {
      animation: totop 2s cubic-bezier(0.8, 0.76, 0.13, 1.06) forwards;
    }
  }
  &.tepada.active {
    img {
      animation: fromtop 2s cubic-bezier(0, 0.82, 0.58, 0.72) forwards;
    }
  }
}

@keyframes tobottom {
  0% {
    scale: 1;
    opacity: 1;
    transform-origin: center center;
  }
  20% {
    translate: 0;
    opacity: 1;
  }
  100% {
    scale: 0.3;

    opacity: 0;
    translate: 0 30%;
  }
}

@keyframes frombottom {
  0% {
    transform-origin: center center;
    scale: 0.3;

    opacity: 0;
    translate: 0 30%;
  }
  20% {
    scale: 0.9;
    translate: 0;
    opacity: 1;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}

@keyframes totop {
  0% {
    scale: 1;
    opacity: 1;
    transform-origin: center center;
  }
  20% {
    scale: 0.9;
    translate: 0;
    opacity: 1;
  }
  100% {
    scale: 0.3;

    opacity: 0;
    translate: 0 -30%;
  }
}

@keyframes fromtop {
  0% {
    transform-origin: center center;
    scale: 0.3;
    opacity: 0;
    translate: 0 -30%;
  }
  20% {
    scale: 0.9;
    translate: 0;
    opacity: 1;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
