.news-profile-comp {
  display: flex;
  gap: 10px;
  align-items: center;
  span {
    color: #fdfdfd80;
    font-size: 12px;
  }
  p {
    font-size: 16px;
  }
  .dot {
    padding: 3px;
    border-radius: 50%;
    background-color: white;
  }
  img {
    max-width: 60px;
  }
}

@media (max-width: 1050px) {
  .news-profile-comp {
    p {
      font-size: 13px;
    }
  }
}
