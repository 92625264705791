.animate-words-outer {
  width: 100%;
  height: 110px;
  position: relative;
  overflow: hidden;
  color: rgb(255, 255, 255);
  opacity: 0;
  transition: 2s;

  &.active {
    opacity: 1;
  }
}
.animate-words-inner {
  height: 100%;
  font-size: 37px;
  display: flex;
  position: absolute;
  animation: toright 10s linear infinite;
  gap: 20px;
  width: 4000px;
  h1 {
    font-family: "Title_Font";
    // width: 50vw;
    // min-width: 800px;
    // background-color: red;
  }
  &.right {
    justify-content: flex-end;
    right: 0;
    animation: toright 20s linear infinite;
  }
  &.left {
    left: 0;
    animation: toleft 20s linear infinite;
  }
}

@media (max-width: 700px) {
  .animate-words-outer {
    height: 70px;
  }
  .animate-words-inner {
    font-size: 18px;
  }
}

@keyframes toright {
  from {
    translate: 0;
  }
  to {
    translate: 800px;
  }
}

@keyframes toleft {
  from {
    translate: 0;
  }
  to {
    translate: -800px;
  }
}
