.students-results-section {
  color: white;
 opacity: 0;
 transition: 2s;
 &.active{
     opacity: 1;
 }
  .slick-next {
    right: 30px;
  }
  .slick-prev {
    left: 30px;
    z-index: 1;
  }
  .slick-current .link {
    scale: 1;
    opacity: 1;
  }
  .slick-active .link {
    opacity: 0.9;
  }
  .link {
    opacity: 0.6;
    transition: 500ms;
    scale: 0.8;
    img {
      border-radius: 1000px;
      object-fit: cover;
      width: 100%;
      height: 100%;
      aspect-ratio: 513/713;
    }
  }
}
