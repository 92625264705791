.custom-slider {
  background-color: white;
  &-container{
    position: relative;
  }
  .main-slider {
    padding-bottom: 100px;
  }
  .slick-next,
  .slick-prev {
    background: linear-gradient(45deg, #90c000, #c0ff03, #ade700);
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    top: auto;
    bottom: -40px;
    &::before {
      display: none;
    }
    div {
      height: 2px;
      width: 40%;
      background-color: rgb(255, 255, 255);
      position: relative;
      
      &::before {
        content: "";
        width: 15px;
        height: 15px;
        position: absolute;
        rotate: -45deg;
        border-top: 3px solid rgb(255, 255, 255);
        border-left: 3px solid rgb(255, 255, 255);
        translate: -2px 1px;
        transform-origin: top left;
      }
    }
  }
  
  .slick-next {
    left: calc(10% + 96px);
    div {
      rotate: 180deg;
    }
  }
  .slick-prev {
    left: 10%;
  }
  .slide-container {
    // background-color: red;
    width: 100%;
    height: 300px;
  }
  &.dark {
    color: white;
    background-color: black;
    .slick-next,
    .slick-prev {
      background: linear-gradient(181.26deg, rgba(192, 255, 3, 0) -33.18%, rgba(192, 255, 3, 0.08) 98.93%);
      backdrop-filter: blur(45px);
      color: rgba(192, 255, 3, 0.466);
      border-top: 1px solid;
      border-bottom: 1px solid;
      
      div {
        background-color: #c0ff03;
        
        &::before {
          border-top: 3px solid #c0ff03;
          border-left: 3px solid #c0ff03;
        }
      }
    }
    .slide-container {
      opacity: 0.4;
    }
    .slick-list {
      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 12%;
        height: 100%;
        z-index: 1;
      }
      &::before {
        left: 0;
        background: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      }
      &::after {
        right: 0;
        background: linear-gradient(to left, rgb(0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      }
    }
  }
}

.custom-slider .slide-container {
  display: flex;
  transition: 1000ms;
  scale: 0.9;
  align-items: flex-start;
  gap: 30px;

  .text {
    padding-top: 20px;
    p {
      font-size: 20px;
      line-height: 26px;
    }
    .job {
      padding: 14px 0 35px;
      color: #b5c9d1;
    }
  }
  h2 {
    font-family: "Title_font";
    font-size: 28px;
  }
}

.custom-slider .slick-active .slide-container {
  opacity: 1;
  scale: 1;
}

@media (max-width: 900px) {
  .custom-slider {
    .slide-container {
      h2 {
        font-size: 25px;
      }
      .text p {
        font-size: 17px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  .custom-slider {
    .title {
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 12px;
        max-width: 150px;
      }
    }
  }
  .custom-slider .main-slider {
    .slick-next,
    .slick-prev {
      width: 60px;
      height: 60px;
      bottom: 40px;
    }
    .slick-next {
      left: calc(10% + 70px);
    }
  }
}

@media (max-width: 480px) {
  .custom-slider .slide-container {
    h2 {
      font-size: 18px;
    }
    .text p {
      font-size: 11px;
      line-height: 16px;
    }
    .text .job {
      padding: 10px 0 20px;
    }
  }
  .custom-slider .title {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 10px;
    }
  }
}
