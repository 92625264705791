.notfound-page {
  overflow: hidden;
  height: 100vh;
  position: relative;
  .content {
    position: absolute;
    inset: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
    color: white;
    overflow: auto;
    h1 {
      font-size: 250px;
      line-height: 303px;
      font-family: "Title_font";
      text-shadow: 0 0 7px white;
    }
    p {
      padding: 12px 0 98px;
      font-size: 20px;
      line-height: 25px;
      max-width: 600px;
    }
  }
}
