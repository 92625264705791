.format-lesson-card-component {
     color: white;
     display: flex;
     flex-direction: column;
     gap: 28px;
     margin: 0 5%;
     scale: 0.5;
     opacity: 0;
     transition: 2s;
     &.active{
          scale: 1;
          opacity: 1;
          
     }
     h1 {
          font-size: 32px;
     }

     h2 {
          font-size: 20px;
          width: 100%;
          color: white;
     }
     p {
          display: flex;
          color: #ffffffb2;
          flex-wrap: wrap;
          gap: 16px;
     }

     @media (max-width: 420px) {
          gap: 14px;
          p {
               font-size: 14px;
               gap: 10px;
          }
          h1 {
               font-size: 24px;
          }
          h2 {
               font-size: 18px;
          }
     }
}
