.scroll-list {
  // margin-top: 50%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 10%;
  gap: 5%;
  overflow: hidden;
  height: 100vh;
  color: white;
  margin-top: 70px;
  position: relative;
  transition: 500ms;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  transition: 2s;
  &.active {
    opacity: 1;
    .image,
    .list-items {
      rotate: 0deg;
      scale: 1;
      translate: 0;
    }

    &::before {
      display: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .image {
    width: 35%;
    aspect-ratio: 8/11;
    align-self: center;
    translate: -100%;
    transition: 2s;
    scale: 0.5s;
  }
  .list-items {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 60%;
    padding: 50vh 0;
    translate: 100%;
    transition: 2.3s;
    scale: 0.7s;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 1050px) {
    .list-item {
      overflow: unset !important;
    }
    height: unset;
  }
  .list-item {
    width: 100%;
    margin: 5px 0;
    transition: 300ms;
    color: white;
    &:hover h3,
    &.active h3 {
      color: #a5dc00;
    }
  }
  .item-content {
    padding: 50px 0;
    display: flex;
    gap: 5%;
  }
  .right-content {
    width: 100%;
  }
  h1,
  h3 {
    font-family: "Title_font";
    transition: 300ms;
  }
  h3 {
    font-size: 20px;
    color: #a5dc0085;
  }
  h1 {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 34px;
  }
  .needs {
    display: flex;
    gap: 5%;
    font-size: 16px;
    color: #fdfdfd;
  }
}

@media (max-width: 1050px) {
  .scroll-list {
    padding: 0 10%;
    .list-items {
      width: 100%;
      padding: 0 !important;
    }
    .list-item {
      translate: 0 !important;
    }
    .image {
      display: none;
    }
  }
}
@media (max-width: 700px) {
  .scroll-list .list-items .list-item {
    .item-content {
      padding: 20px 0;
      display: flex;
      gap: 5%;
    }
    h3 {
      font-size: 18px;
    }
    h1 {
      font-size: 27px;
      line-height: normal;
      margin-bottom: 20px;
    }
    .needs {
      display: flex;
      gap: 5%;
      font-size: 12px;
      color: #fdfdfd;
    }
  }
}

@media (max-width: 400px) {
  .scroll-list .list-items .list-item {
    gap: 10%;
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 15px;
    }
    .needs {
      font-size: 10px;
    }
  }
}
