.consultant-section {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 120px 10%;
  position: relative;
  p {
    max-width: 455px;
    font-size: 20px;
    padding: 40px 0 130px;
    translate: -70%;
    opacity: 0;
    transition: 2s;
  }
  h1 {
    max-width: 1140px;
    font-family: "Title_font";
    font-size: 5vw;
    opacity: 0;
    translate: 70%;
    opacity: 0;
    transition: 2s;
  }
  form {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    translate: 0 200px;
    transition: 2.5s;
    opacity: 0;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }

  &.dark{
    background-color: transparent;
    color: white;
    p{
      color: #FDFDFD;
    }
  }
   
}

.consultant-section.active {
  p,
  h1,
  form {
    translate: 0;
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .consultant-section {
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 15px;
      padding: 22px 0 55px;
    }
    input {
      padding: 15px 30px;
      display: inline-block;
      width: 100%;
    }
    form {
      align-items: stretch;
    }
  }
}
@media (max-width: 600px) {
  .consultant-section {
    h1 {
      font-size: 7vw;
    }
    p {
      font-size: 13px;
    }
    input {
      padding: 12px 24px;
      display: inline-block;
      width: 100%;
      font-size: 14px;
    }
  }
}
