.menu,
.menu-background {
     position: fixed;
     top: 70px;
     width: 100%;
     height: calc(100vh - 70px);
     left: 0;
     z-index: 99;
     background-color: rgb(0, 0, 0);
     transition: 1s;
     color: white;
     clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

     &.active {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
     }
}

.menu {
     overflow-y: auto;
     display: flex;
     gap: 2%;
     justify-content: space-between;
     padding: 100px 10%;
     width: 100vw;
     &::-webkit-scrollbar {
          display: none;
     }
     h3,
     h1 {
          font-family: "Title_font";
     }
     .socials {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          max-width: 500px;
          width: 50%;
          gap: 10px;
          .email a {
               font-size: 20px;
               color: #fdfdfd;
               margin-top: 10px;
          }
          h3 {
               font-size: 24px;
          }
          .icons {
               display: flex;
               gap: 5%;
          }
          .whatelse h3 {
               max-width: 400px;
               margin-bottom: 20px;
          }
     }
     .center {
          width: 30%;
          display: flex;
          flex-direction: column;
          text-align: end;
          gap: 10px;
          color: #fdfdfd;
     }
     .pages {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          position: relative;
          .center {
               position: absolute;
               right: 107%;
               top: 0;
               width: 100%;
               transition: 500ms;
               clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          }
          & > div:hover {
               .center {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
               }
          }
          h1 {
               transition: 300ms;
          }
          h1:hover {
               color: #c0ff03;
               scale: 1.2 1.05;
               transform-origin: left center;
          }
     }
}
.menu-background.active {
     top: 0;
     height: 100%;
}

@media (max-width: 1070px) {
     .menu {
          flex-wrap: wrap;
          row-gap: 100px;
          .center,
          .pages {
               width: 49%;
          }
          .socials {
               order: 3;
               width: 100%;
               display: flex;
               flex-wrap: wrap;
               flex-direction: row;
               justify-content: space-between;
               max-width: none;
               row-gap: 50px;
          }
     }
}
@media (max-width: 620px) {
     .menu {
          .center {
               display: none;
          }
          .center,
          .pages {
               p {
                    font-size: 12px;
               }
               h1 {
                    font-size: 30px;
               }
               width: 48%;
          }
          .socials {
               h3 {
                    font-size: 17px;
               }
               .email a {
                    font-size: 14px;
               }
          }
     }
}
@media (max-width: 500px) {
     .menu {
          .center,
          .pages {
               p {
                    font-size: 11px;
               }
               h1 {
                    font-size: 6vw;
               }
               width: 48%;
          }
     }
     .menu-button p {
          display: none;
     }
}
