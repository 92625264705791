.lesson-card-component {
     color: white;
     display: flex;
     flex-direction: column;
     gap: 44px;
     margin-right: 20%;
     font-size: 13px;
     scale: 0.5;
     opacity: 0;
     transition: 2s;
     &.active{
          scale: 1;
          opacity: 1;
          
     }
     h1 {
          font-size: 32px;
     }

     h2 {
          font-size: 13px;
          width: 100%;
          color: white;
     }
     .lang {
          display: flex;
          color: #ffffffb2;
          flex-wrap: wrap;
          gap: 16px;
     }

     @media (max-width: 420px) {
          gap: 30px;
          .lang {
               gap: 10px;
          }
     }
}
