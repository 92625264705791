.logo-comp {
    max-width: 200px;
  width: 100%;
  height: 100%;
   object-fit: contain;
  animation: logo 10s linear   infinite;
}

@keyframes logo {
  0% {
   }
  90% {
    transform: rotateX(0deg);
  }
  100% {
     transform: rotateX(360deg);
  }
}
